export default {
	data:[
		{
			stateCN:"亚拉巴马",
			stateUS:"Alabama",
			stateCode:"AL",
			phoneAreaCode:"205 - 251 - 256 - 334",
			statePostCode:[{
				city:"伯明翰(Birmingham)",
				postCode:"35201~35238、35240、35242~35246、35249、35253~35255、35259~35261、35263、35266、35277~35283、35285~35299"
			},{
				city:"蒙哥马利(Montgomery)",
				postCode:"36101~36125、36130~6135、36140~36142、36177、36191"
			},{
				city:"亨次维尔(Huntsville)",
				postCode:"35801~35816、35824、35893~35899"
			},{
				city:"土斯卡鲁沙(Tuscaloosa)",
				postCode:"35401~35407、35485~35487"
			},{
				city:"木比耳(Mobile)",
				postCode:"36601~36628、36630~36633、36640、36641、36644、36652、36660、36663、36670、36671、36675、36685、36688~36691、36693、36695"
			}],
			
		},
		{
			stateCN:"阿拉斯加",
			stateUS:"Alaska",
			stateCode:"AK",
			phoneAreaCode:"907",
			statePostCode:[{
				city:"朱诺(Juneau)",
				postCode:"99801~99803、99811、99850"
			},{
				city:"安克拉奇(Anchorage)",
				postCode:"99501~99504、99507~99599、99695"
			},{
				city:"费尔班克斯(Fairbanks)",
				postCode:"99701、99706~99712、99775、99790"
			}]
		},
		{
			stateCN:"亚利桑那",
			stateUS:"Arizona",
			stateCode:"AZ",
			phoneAreaCode:"480 - 520 - 602 - 623 - 928",
			statePostCode:[{
				city:"菲尼克斯[凤凰城](Phoenix)",
				postCode:"85001~85046、85048、85050、85051、85053~85055、85060~85080、85082、85085、85086、85098、85099"
			},{
				city:"吐桑(Tucson)",
				postCode:"85701~85726、85728、85730、85737、85739、85752、85754、85775、85777"
			},{
				city:"孟沙(Mesa)",
				postCode:"85201~85208、85210！85216、85274、85275、85277"
			}]
		},
		{
			stateCN:"阿肯色",
			stateUS:"Arkansas",
			stateCode:"AR",
			phoneAreaCode:"501 - 870",
			statePostCode:[{
				city:"小石城(Little Rock)",
				postCode:"72201~72207、72209~72217、72219、72221~72223、72225、72227、72231、72260、72295"
			},{
				city:"菲页维尔(Fayetteville)",
				postCode:"72701~72704"
			}]
		},
		{
			stateCN:"加利福尼亚",
			stateUS:"California",
			stateCode:"CA",
			phoneAreaCode:"209 - 213 - 310 - 323 - 408 - 415 - 510 - 530 - 559 - 562 - 619 - 626 - 650 - 661 - 707 - 714 - 760 - 805 - 818 - 831 - 858 - 909 - 916 - 925 - 949",
			statePostCode:[{
				city:"萨克拉门托(Sacramento)",
				postCode:"94203~94209、94211、94229、94230、94232、94234~94237、94239、94240、94243~94250、94252~94254、94256~94259、94261~94263、94267~94269、94271、94273、94274、94277~94280、94282~94291、94293~94299、95812~95838、95840~95842、95851~95853、95857、95860、95864~95867、95873、95887、95894、95899"
			},{
				city:"索诺马(Sonoma)",
				postCode:"95476"
			},{
				city:"圣荷西(San Jose)",
				postCode:"95101~95103、95106、95108~95139、95141、95142、95148、95150~95161、95164、95170~95173、95190~95194、95196"
			},{
				city:"洛杉矶(Los Angeles)",
				postCode:"90001~90068、90070~90084、90086~90097、90099、90101~90103、90174、90185、90189"
			},{
				city:"圣地亚哥(San Diego)",
				postCode:"92101~92117、92119~92124、92126~92140、92142、92145、92147、92149、92150、92152~92155、92158~92172、92174~92177、92179、92182、92184、92186、92187、92190~92199"
			},{
				city:"旧金山(San Francisco)",
				postCode:"94101~94112、94114~94147、94150~94157、94159~94172、94175、94177、94188"
			}]
		},
		{
			stateCN:"科罗拉多",
			stateUS:"Colorado",
			stateCode:"CO",
			phoneAreaCode:"303 - 719 - 720 - 970",
			statePostCode:[{
				city:"丹佛(Denver)",
				postCode:"80201~80212、80214~80239、80241、80243、80244、80246~80252、80255~80257、80259~80266、80270、80271、80273~80275、80279~80281、80285、80290~80295"
			},{
				city:"波尔德(Boulder)",
				postCode:"80301~80310、80314、80321~80323、80328、80329"
			},{
				city:"科罗拉多泉(Clolrado Springs)",
				postCode:"80901、80903~80922、80925、80926、80928~80937、80940~80947、80949、80950、80960、80962、80970、80977、80995、80997"
			}]
		},
		{
			stateCN:"康涅狄格",
			stateUS:"Connecticut",
			stateCode:"CT",
			phoneAreaCode:"203 - 860",
			statePostCode:[{
				city:"哈特福(Hartford)",
				postCode:"06101~06106、06112、06114、06115、06120、06123、06126、06132、06134、06140~06147、06150~06156、06160、06161、06167、06176、06180、06183、06199"
			}]
		},
		{
			stateCN:"特拉华",
			stateUS:"Delaware",
			stateCode:"DE",
			phoneAreaCode:"302",
			statePostCode:[{
				city:"多佛(Dover)",
				postCode:"19901、19903、19904~19906"
			},{
				city:"维明顿(Wilmington)",
				postCode:"19801~19810、19850、19880、19884~19887、19889~19899"
			},{
				city:"纽华克(Newark)",
				postCode:"19702、19711~19718、19725、19726"
			}]
		},
		{
			stateCN:"华盛顿特区/哥伦比亚特区",
			stateUS:"Washington/District of Columbia",
			stateCode:"DC",
			phoneAreaCode:"202",
			
		},
		{
			stateCN:"佛罗里达",
			stateUS:"Florida",
			stateCode:"FL",
			phoneAreaCode:"305 - 321 - 352 - 386 - 407 - 561 - 727 - 754 - 772 - 786 - 813 - 850 - 863 - 904 - 941 - 954",
			statePostCode:[{
				city:"塔拉赫西(Tallahassee)",
				postCode:"32301~32318、32395、32399"
			},{
				city:"坦帕(Tampa)",
				postCode:"33601~33626、33629~33631、33633~33635、33637、33647、33650、33651、33655、33660~33664、33672~33675、33677、33679~33682、33684~33690、33694、33697"
			},{
				city:"杰克逊维尔(Jacksonville)",
				postCode:"32099、32201~32212、32214~32232、32234~32239、32241、32244~32247、32254~32258、32259、32260、32267、32276、32277"
			},{
				city:"迈阿密(Miami)",
				postCode:"33101、33102、33107、33110~33112、33114、33116、33121、33122、33124~33138、33142~33148、33150~33153、33155~33159、33161~33170、33172~33190、33192~33197、33199、33231、33233、33234、33238、33242、33243、33245、33247、33255~33257、33261、3265、33266、33269、33280、33283、33296、33299"
			},{
				city:"根斯维尔(Gainesville)",
				postCode:"32601~32614、32627、32635、32641、32653"
			}]
		},
		{
			stateCN:"乔治亚",
			stateUS:"Georgia",
			stateCode:"GA",
			phoneAreaCode:"229 - 404 - 478 - 678 - 706 - 770 - 912",
			statePostCode:[{
				city:"亚特兰大(Atlanta)",
				postCode:"30301~30328、30329、30330~30332、30333、30334、30336、30337、30338、30339、30340、30341、30342~30344、30345、30346、30347~30349、30350、30353~30355、30356、30357、30358、30359、30360 、30361、30362、30364、30366、30368~30371、30374~30380、30384~30390、30392、30394、30396、30398、30399、31106、31107、31119、31126、31131、31132、31139、31141、31145、31146、31150、31156、31191~31193、31195~31199、39901"
			},{
				city:"哥伦布(Columbus)",
				postCode:"31901~31904、31906~31909、31914、31917、31993、31994、31997~31999"
			},{
				city:"美昆(Macon)",
				postCode:"31201~31213、31216、31217、31220、31221、31294~31299"
			}]
		},
		
		{
			stateCN:"夏威夷",
			stateUS:"Hawaii",
			stateCode:"HI",
			phoneAreaCode:"808",
			statePostCode:[{
				city:"檀香山(Honolulu)",
				postCode:"96801~96828、96830、96835~96844、96846~96850"
			}]
		},{
			stateCN:"爱达荷",
			stateUS:"Idaho",
			stateCode:"ID",
			phoneAreaCode:"208",
			statePostCode:[{
				city:"波夕(Boise)",
				postCode:"83701~83709、83711~83713、83715~83717、83719~83733、83735、83744、83756、83757、83799"
			},{
				city:"波格太罗(Pocatello)",
				postCode:"83201、83202、83204~83206、83209"
			},{
				city:"爱达荷泉(Idaho Falls)",
				postCode:"83401~83406、83415"
			}]
		},
		{
			stateCN:"伊利诺伊",
			stateUS:"Illinois",
			stateCode:"IL",
			phoneAreaCode:"217 - 309 - 312 - 618 - 630 - 708 - 773 - 815 - 847",
			statePostCode:[{
				city:"春田(Springfield)",
				postCode:"62701~62708、62713、62715、62716、62719、62721~62723、62726、62736、62739、62746、62756、62757、62761~62767、2769、62776、62777、62781、62786、62791、62794、62796"
			},{
				city:"芝加哥(Chicago)",
				postCode:"60601~60626、60628~60634、60636~60641、60643~6064760649、60651~60661、60663~60665、60667~60675、60677~60688、60690、60691、60693~6069、60699、60701"
			},{
				city:"洛克福特(Rockford)",
				postCode:"61101~61110、61112、61114、61125、61126"
			}]
		},
		{
			stateCN:"印第安纳",
			stateUS:"Indiana",
			stateCode:"IN",
			phoneAreaCode:"219 - 260 - 317 - 574 - 765 - 812",
			statePostCode:[{
				city:"印第安纳波利斯(Indianapolis)",
				postCode:"46201~46209、46211、46214、46216~46231、46234~46237、46239~46242、46244、46247、46249~46251、46253~46256、46259、46260、46266、46268、46274、46275、46277、46278、46280、46282、46283、46285、46290、46291、46295、46296、46298"
			},{
				city:"韦恩堡(Fort Wayne)",
				postCode:"46801~46809、46814~46816、46818、46819、46825、46835、46845"
			},{
				city:"Fort Wayne Indiana Allen",
				postCode:"46850~46869、46885、46895~46899"
			},{
				city:"布降明顿(Bloomington)",
				postCode:"47401~47408、47490"
			},{
				city:"拉法叶(Lafayette)",
				postCode:"47901~47905、47909"
			}]
		},
		{
			stateCN:"艾奥瓦/衣阿华",
			stateUS:"Iowa",
			stateCode:"IA",
			phoneAreaCode:"319 - 515 -563 - 641 - 712",
			statePostCode:[{
				city:"第蒙(Des Moines)",
				postCode:"50301~50321、50327~50336、50338~50340、50347、50350、50359、50360~50364、50367~50381、50391~50397、50936、50940、50947、50950、50980、50981"
			},{
				city:"西达拉皮兹(Cedar Rapids)",
				postCode:"52401~52411、52497~52499"
			},{
				city:"丹芬堡特(Daven Port)",
				postCode:"52801~52809"
			},{
				city:"衣阿华城(Iowa City)",
				postCode:"52240、52242~52246"
			}]
		},
		{
			stateCN:"堪萨斯",
			stateUS:"Kansas",
			stateCode:"KS",
			phoneAreaCode:"316 - 620 - 785 - 913",
			statePostCode:[{
				city:"托皮卡(Topeka)",
				postCode:"66601、66603~66612、66614~66622、66624~66626、66628、66629、66634、66636~66638、66642、66647、66652、66653、66658、66667、66675、66683、66686、66692、66699"
			},{
				city:"维契托(Wichita)",
				postCode:"67201~67220、67223、67226~67228、67230~67233、67235、67236、67251、67256、67257、67259、67260、67275~67278"
			},{
				city:"堪萨斯城(Kansas City)",
				postCode:"66101~66106、66109~66112、66115、66117~66119、66160"
			},{
				city:"罗伦斯(Lawrence)",
				postCode:"66044~66047、66049"
			}]
		},
		{
			stateCN:"肯塔基",
			stateUS:"Kentucky",
			stateCode:"KY",
			phoneAreaCode:"270 - 502 - 606 - 859",
			statePostCode:[{
				city:"路易斯维尔(Louisville)",
				postCode:"40201~40225、40228、40229、40231~40233、40241~40243、40245、40250~40253、40255~40259、40261、40266、40268~40270、40272、40280~40283、40285、40287、40289~40299"
			},{
				city:"雷克星敦(Lexington)",
				postCode:"40502~40517、40522~40524、40526、40533、40536、40544、40546、40550、40555、40574~40596、40598"
			}]
		},
		{
			stateCN:"路易斯安那",
			stateUS:"Louisiana",
			stateCode:"LA",
			phoneAreaCode:"225 - 318 - 337 - 504 - 985",
			statePostCode:[{
				city:"新奥尔良(New Orleans)",
				postCode:"70112~70119、70121~70131、70139~70143、70145、70146、70148~70154、70156~70167、70170、70172、70174~70179、70181~70190、70195"
			}]
		},
		{
			stateCN:"缅因",
			stateUS:"Maine",
			stateCode:"ME",
			phoneAreaCode:"207",
			statePostCode:[{
				city:"奥古斯塔(Augusta)",
				postCode:"04330、04332、04333、04336、04338"
			},{
				city:"波特兰(Portland)",
				postCode:"04101~04104、04109、04112、04122~04124"
			}]
		},
		{
			stateCN:"马里兰",
			stateUS:"Maryland",
			stateCode:"MD",
			phoneAreaCode:"240 - 301 - 410 - 443",
			statePostCode:[{
				city:"亚那波里斯(Annapolis)",
				postCode:"21001、21401~21405、21411、21412"
			},{
				city:"巴尔的摩(Baltimore)",
				postCode:"21201~21203、21205、21206、21209~21218、21223、21224、21229~21231、21233、21235、21239、21240、21241、21250~21252、21263~21265、21268、21270、21273~21275、21278~21281、21282、21283、21284、1285、21287~21290、21297、21298"
			},{
				city:"洛克威尔(Rockville)",
				postCode:"20847~20853、20857"
			}]
		},
		{
			stateCN:"马萨诸塞/麻萨诸塞",
			stateUS:"Massachusetts",
			stateCode:"MA",
			phoneAreaCode:"339 - 351 - 413 - 508 - 617 - 774 - 781 - 857 - 978",
			statePostCode:[{
				city:"波士顿(Boston)",
				postCode:"02101~02125、02127、02128、02133、02163、02196、02199、02201、02203~02212、02215~02217、02222、02241、02266、02283、02284、02293、02295、02297"
			},{
				city:"伍斯特(Worcester)",
				postCode:"01601~01610、01613~01615、01653~01655"
			}]
		},
		{
			stateCN:"密歇根",
			stateUS:"Michigan",
			stateCode:"MI",
			phoneAreaCode:"231 - 248 - 269 - 313 - 517 - 586 - 616 - 734 - 810 - 906 - 989",
			statePostCode:[{
				city:"兰辛(Lansing)",
				postCode:"48901、48906~48913、48915~48919、48921、48922、48924、48929、48930、48933、48937、48950、48951、48956、48980"
			},{
				city:"底特律(Detroit)",
				postCode:"48201、48202、48204~48211、48213~48217、48219、48221~48224、48226~48228、48231~48235、48238、48242~48244、48255、48260、48264~48269、48272、48275、48277~48279、48288"
			},{
				city:"大溪城(Grand Rapids)",
				postCode:"49501~49510、49512、49514~49516、49518、49523、49525、49530、49544、49546、49548、49550、49555、49560、49588、49599"
			},{
				city:"林特(flint)",
				postCode:"48501~48507、48531、48532、48550~48557、48559"
			},]
		},
		{
			stateCN:"明尼苏达",
			stateUS:"Minnesota",
			stateCode:"MN",
			phoneAreaCode:"218 - 320 - 507 - 612 - 651 - 763 - 952",
			statePostCode:[{
				city:"圣保罗(Saint Paul)",
				postCode:"55101~55129、55133、55144~55146、55155、55161、55164~55166、55168~55172、55175、55177、55182、55187、55188、55190、55191"
			},{
				city:"明尼阿波利斯(Minneapolis)",
				postCode:"55401~55450、55454、55455、55458~55460、55468、55470、55472~55474、55478~55480、55483~55488"
			},{
				city:"杜鲁司(Duluth)",
				postCode:"55801~55808、55810~55812、55814~55816"
			}]
		},
		{
			stateCN:"密西西比",
			stateUS:"Mississippi",
			stateCode:"MS",
			phoneAreaCode:"228 - 601 - 662",
			statePostCode:[{
				city:"杰克逊(Jackson)",
				postCode:"39201~39207、39209~39213、39215~39217 ~39225、39235、39236、39250、39269、39271、39282~39284、39286、39289、39296、39298"
			},{
				city:"密烈地安(Meridian)",
				postCode:"39301~39305、39307、39309"
			}]
		},
		{
			stateCN:"密苏里",
			stateUS:"Missouri",
			stateCode:"MO",
			phoneAreaCode:"314 - 417 - 573 - 636 - 660 - 816",
			statePostCode:[{
				city:"杰佛逊城(Jefferson City)",
				postCode:"65101~65111"
			},{
				city:"圣路易斯(Saint Louis)",
				postCode:"63101~63104、63105、63106~63141、63143~63147、63150、63151、63155~63158、63160、63163、63164、63166、63167 、63169、63171、63177~63180、63182、63188、63195~63199"
			},{
				city:"堪萨斯城(Kansas City)",
				postCode:"64101、64102、64105、64106、64108~64114、64116~64121、64123~64134、64136~64142、64144~64149、64151~64158、64160、64161、64163~64168、64170~64173、64179、64180、64183~64185、64187~64199、64944、64999"
			},{
				city:"洛拉(Rolla)",
				postCode:"65401、65402、65409"
			}]
		},
		{
			stateCN:"蒙大拿",
			stateUS:"Montana",
			stateCode:"MT",
			phoneAreaCode:"406",
			statePostCode:[{
				city:"赫勒拿(Heldna)",
				postCode:"59601、59602、59604、59620、59623~59626"
			},{
				city:"比林斯(Billings)",
				postCode:"59101~59108、59111、59112、59114~59117"
			},{
				city:"密苏拉(Missoula)",
				postCode:"59801~59804、59806~59808、59812"
			}]
		},
		{
			stateCN:"内布拉斯加",
			stateUS:"Nebraska",
			stateCode:"NE",
			phoneAreaCode:"308 - 402",
			statePostCode:[{
				city:"林肯(Lincoln)",
				postCode:"68501~68510、68512、68514~68517、68520~68524、68526~68529、68531、68532、68542、68583、68588"
			},{
				city:"奥马哈(Omaha)",
				postCode:"68101~68112、68114、68116~68120、68122、68124、68127、68130~68132、68134、68135、68136、68137、68138、68139、68142、68144、68145、68152、68154、68155、68157、68164、68172、68175、68176、68178 ~68183、68197、68198"
			}]
		},
		{
			stateCN:"内华达",
			stateUS:"Nevada",
			stateCode:"NV",
			phoneAreaCode:"702 - 775",
			statePostCode:[{
				city:"喀孙城(Carson City)",
				postCode:"89701~89703、89705、89706、89711~89714、89721"
			},{
				city:"拉斯维加斯(Las Vegas)",
				postCode:"89101~89104、89106~89135、89137~89139、89141~89156、89159、89160、89162、89164、89170、89173、89177、89180、89185、89193、89195、89199"
			},{
				city:"雷诺(Reno)",
				postCode:"89501~89507、89509~89513、89515、89520、89521、89523、89533、89555、89557、89570、89595、89599"
			}]
		},
		{
			stateCN:"新罕布什尔",
			stateUS:"New Hampshire",
			stateCode:"NH",
			phoneAreaCode:"603",
			statePostCode:[{
				city:"曼彻斯特(Manchester)",
				postCode:"03101~03105、03107~03109、03111"
			},{
				city:"南雪(Nashua)",
				postCode:"03060~03064"
			},{
				city:"朴次茅斯(Portsmouth)",
				postCode:"03801~03804"
			}]
		},
		{
			stateCN:"新泽西",
			stateUS:"New Jersey",
			stateCode:"NJ",
			phoneAreaCode:"201 - 609 - 732 - 856 - 908 - 973",
			statePostCode:[{
				city:"纽瓦克(Newark)",
				postCode:"07101~07108、07112、07114、07175、07182、07184、07188、07189、07191~07195、07197~07199"
			},{
				city:"泽西市(Jersey City)",
				postCode:"07097、07302~07311、07399"
			},{
				city:"大西洋城(Atlantic City)",
				postCode:"08401、08404、08405"
			},{
				city:"依丽沙白(Elizabeth)",
				postCode:"07201、07202、07206~07208"
			}]
		},
		{
			stateCN:"新墨西哥",
			stateUS:"New Mexico",
			stateCode:"NM",
			phoneAreaCode:"505",
			statePostCode:[{
				city:"圣塔非(santaFe)",
				postCode:"87500~87509、87592、87594"
			},{
				city:"阿布奎基(Albuquerque)",
				postCode:"87101~87116、87118~87123、87125、87131、87151、87153、87154、87158、87176、87181 、87184、87185、87187、87190~87199"
			}]
		},
		{
			stateCN:"纽约",
			stateUS:"New York",
			stateCode:"NY",
			phoneAreaCode:"212 - 315 - 347 - 516 - 518 - 607 - 631 - 646 - 716 - 718 - 845 - 914 - 917",
			statePostCode:[{
				city:"奥尔巴尼[水牛城](Albany)",
				postCode:"12201~12212、12214、12220、12222~12250、12252、12255~12257、12260、12261、12262、12288"
			},{
				city:"布法罗(Buffalo)",
				postCode:"14201~14228、14231、14233、14240、14241、14260、14261、14263~14265、14267、14269、14270、14272、14273、14276、14280"
			},{
				city:"长岛(Long Island)",
				postCode:"11101、11109、11120"
			},{
				city:"纽约(New York City)",
				postCode:"10001~10041、10043~10048、10055、10060、10069、10072、10079~10082、10087、10090、10094~10096、10098、10099、10101~10126、10128~10133、10138、10149~10179、10184、10185、10196、10197、10199、10203、10211~10213、10242、10249、10256~10261、10265、10268~10282、10285、10286、10292"
			},{
				city:"罗契斯特(Rochester)",
				postCode:"14602~14627、14638、14639、14642~14647、14649~14653、14664、14673、14683、14692、14694"
			},{
				city:"绮色佳(Ithaca)",
				postCode:"14850~14853"
			}]
		},
		{
			stateCN:"北卡罗来纳",
			stateUS:"North Carolina",
			stateCode:"NC",
			phoneAreaCode:"252 - 336 - 704 - 828 - 910 - 919 - 980",
			statePostCode:[{
				city:"洛利(Raleigh)",
				postCode:"27601~27629、27634~27636、27640、27650、27656、27658、27661、27668、27675、27676、27690、27695、27697~27699"
			},{
				city:"沙洛特(Charlotte)",
				postCode:"28201~28237、28240~28244、28246、28247、28250、28253~28256、28258、28260~28262、28265、28266、28269~28275、28277、28278、28280~28290、28296、28297、28299"
			},{
				city:"格陵斯堡(Greensboro)",
				postCode:"27401~27417、27419、27420、27425、27427、27429、27435、27438、27455、27480、27495、27498、27499"
			},{
				city:"查伯山(Chapel Hill)",
				postCode:"27514~27517、27599"
			},{
				city:"阿士维尔(Asheville)",
				postCode:"28801~28806、28810、28813~28816"
			}]
		},
		{
			stateCN:"北达科他",
			stateUS:"North Dakota",
			stateCode:"ND",
			phoneAreaCode:"701",
			statePostCode:[{
				city:"俾斯麦(Bismark)",
				postCode:"58501~58507"
			},{
				city:"法哥(Fargo)",
				postCode:"58102~58109、58121~58126"
			}]
		},
		{
			stateCN:"俄亥俄",
			stateUS:"Ohio",
			stateCode:"OH",
			phoneAreaCode:"216 - 234 - 330 - 419 - 440 - 513 - 614 - 740 - 937",
			statePostCode:[{
				city:"哥伦布(Columbus)",
				postCode:"43085、43201~43224、43226~43232、43234、43235、43236、43240、43251、43260、43265、43266、43268~43271、43272、43279、43287、43291、43299"
			},{
				city:"克利夫兰(Cleveland)",
				postCode:"44101~44106、44108~44115、44118~44121、44124~44130、44134、44135、44143、44144、44178、44181、44185、44188、44189、44190~44195、44197~44199"
			},{
				city:"辛辛那提(Cincinnati)",
				postCode:"45201~45255、45258、45262~45264、45267~45273、45274、45275、45277、45280、45296、45298、45299、45944、45999"
			}]
		},
		{
			stateCN:"俄克拉荷马",
			stateUS:"Oklahoma",
			stateCode:"OK",
			phoneAreaCode:"405 - 580 - 918",
			statePostCode:[{
				city:"俄克拉荷马城(OklahomaCity)",
				postCode:"73101~73132、73134~73137、73139、73140~73157、73159、73160、73162~73165、73167、73169、73170、73172、73173、73178、73179、73184、73185、73189、73190、73193~73199"
			},{
				city:"吐沙(Tulsa)",
				postCode:"74101~74108、74110、74112、74114~74117、74119~74121、74126~74137、74141、74145~74150、74152、74153、74155~74159、74169~74172、74182~74184、74186、74187、74189、74192~74194"
			},{
				city:"劳顿(Lawton)",
				postCode:"73501、73502、73505~73507"
			},{
				city:"诺曼城(Norman)",
				postCode:"73019、73026、73069、73070~73072"
			}]
		},
		{
			stateCN:"俄勒冈州",
			stateUS:"Oregon",
			stateCode:"OR",
			phoneAreaCode:"503 - 541 - 971",
			statePostCode:[{
				city:"沙连(Salem)",
				postCode:"97301~97305、97306、97308~97314"
			},{
				city:"波特兰(Portland)",
				postCode:"97201~97225、97227~97233、97236、97238~97240、97242、97251、97253~97256、97258、97259、97266~97269、97271、97272、97280~97283、97286、97290、97291、97292~97294、97296、97298、97299"
			},{
				city:"尤金(Eugene)",
				postCode:"97401~97405、97408、97440"
			},{
				city:"科瓦利(Corvallis)",
				postCode:"97330、97331、97333、97339"
			}]
		},
		{
			stateCN:"宾夕法尼亚",
			stateUS:"Pennsylvania",
			stateCode:"PA",
		},
		{
			stateCN:"罗得岛",
			stateUS:"Rhode Island",
			stateCode:"RI",
		},
		{
			stateCN:"南卡罗来纳",
			stateUS:"South Carolina",
			stateCode:"SC",
			phoneAreaCode:"803 - 843 - 864",
			statePostCode:[{
				city:"哥伦比亚(Columbia)",
				postCode:"29201~29211、29212、29214~29227、29228、29229、29230、29240、29250、29260、29290、29292"
			},{
				city:"查理斯敦(North Charleston)",
				postCode:"29405、29410、29415、29418~29420"
			},{
				city:"格林威尔(Greenville)",
				postCode:"29601 ~29617、29698"
			},{
				city:"阿干(Aiken)",
				postCode:"29801~29805、29808"
			},{
				city:"美特尔沙滨(Myrtle Beach)",
				postCode:"29572、29575、29577~29579、29587、29588"
			},{
				city:"克伦孙(Clemson)",
				postCode:"29631~29634"
			}]
		},
		{
			stateCN:"南达科他",
			stateUS:"South Dakota",
			stateCode:"SD",
			phoneAreaCode:"605",
			statePostCode:[{
				city:"苏瀑布(Sioux Falls)",
				postCode:"57193、57101、57103~57110、57117、57118、57186、57188、57189、57192、57194~57198"
			},{
				city:"拉皮特城(Rapid City)",
				postCode:"57701~57703"
			},{
				city:"Rapid City South Dakota Pennington",
				postCode:"57709"
			}]
		},
		{
			stateCN:"田纳西",
			stateUS:"Tennessee",
			stateCode:"TN",
			phoneAreaCode:"605",
			statePostCode:[{
				city:"那什维尔(Nemphis)",
				postCode:"37201、37222、37224、37227、37230、37232、37234、37250"
			},{
				city:"孟菲斯(Memphis)",
				postCode:"37501、38101、38103~38120、38122、38124~38137、38140~38143、38145~38148、38150~38152、38157、38159、38161、38163、38165~38168、38173~38175、38177、38181、38182、38184、38186~38188、38190、38193~38195、38197"
			},{
				city:"诺克斯维尔(Knoxville)",
				postCode:"37901、37902、37909、37912、37914、37924、37927~37933、37938~37940、37950、37955、37990、37995~37998"
			},{
				city:"橡岭(Oak Ridge)",
				postCode:"37830、37831"
			}]
		},
		{
			stateCN:"得克萨斯",
			stateUS:"Texas",
			stateCode:"TX",
			phoneAreaCode:"210 - 214 - 254 - 281 - 361 - 409 - 469 - 512 - 682 - 713 - 806 - 817 - 830 - 832 - 903 - 915 - 936 - 940 - 956 - 972 - 979",
			statePostCode:[{
				city:"奥斯丁(Austin)",
				postCode:"73301、73344、78701~78705、78708~78739、78741、78742、78744~78769、78772~78774、78778~78781、78783、78785、78786、78788、78789、78799"
			},{
				city:"休斯顿(Houston)",
				postCode:"77001~77052、77053、77054~77099、77201~77210、77212、77213、77215~77231、77233~77238、77240~77245、77248、77249、77251~77259、77261~77263、77265~77275、77277、77279~77282、77284、77287~77293、77297~77299"
			},{
				city:"达拉斯Dallas",
				postCode:"75201~75212、75214~75254、75258、75260~75267、75270、75275、75277、75283~75287、75295、75301、75303、75310、75312、75313、75315、75320、75323、75326、75336、75339、75342、75346、75353~75357、75359、75360、75363、75364、75367~75374、75376、75378~75382、75386~75398"
			}]
		},
		{
			stateCN:"犹他",
			stateUS:"Utah",
			stateCode:"UT",
			phoneAreaCode:"435 - 801",
			statePostCode:[{
				city:"盐湖城(Salt Lake City)",
				postCode:"84101~84128、84130~84134、84136、84138、84139、84141、84143~84145、84147、84148、84150~84153、84157、84158、84165、84170、84171、84180、84184、84189、84190、84199"
			},{
				city:"奥格但(Ogden)",
				postCode:"84201、84244、84401~84405、84407~84409、84412、84414、84415"
			},{
				city:"普罗伏(Provo)",
				postCode:"84601~84606"
			}]
		},
		{
			stateCN:"佛蒙特",
			stateUS:"Vermont",
			stateCode:"VT",
			phoneAreaCode:"802",
			statePostCode:[{
				city:"勒特兰(Rutland)",
				postCode:"05701、05702"
			}]
		},
		{
			stateCN:"弗吉尼亚",
			stateUS:"Virginia",
			stateCode:"VA",
			phoneAreaCode:"276 - 434 - 540 - 571 - 703 - 757 - 804",
			statePostCode:[{
				city:"里奇蒙(Richmond)",
				postCode:"23218~23225、23226~23231、23232、23233、23234~23237、23238、23240~23242、23249、23250、23255、23260、23261、23269、23270、23272~23276、23278、23279、23280、23282、23284~23286、23288、23289、23290~23293、23294、23295、23297、23298"
			},{
				city:"诺福克(Norfolk)",
				postCode:"23501~23515、23517~23521、23523、23529、23530、23541、23551"
			},{
				city:"弗吉尼亚滩(Virginia Beach)",
				postCode:"23450~23468、23471、23479"
			}]
		},
		{
			stateCN:"华盛顿",
			stateUS:"Washington",
			stateCode:"WA",
			phoneAreaCode:"206 - 253 - 360 - 425 - 509",
			
		},
		{
			stateCN:"罗得岛州",
			stateUS:"Rhode Island",
			stateCode:"RI",
			phoneAreaCode:"401",
			statePostCode:[{
				city:"普洛威顿斯(Providence)",
				postCode:"02901~02909、02912、02918、02940"
			},{
				city:"纽保特(Newport)",
				postCode:"02840、02841"
			}]
		},
		{
			stateCN:"西弗吉尼亚",
			stateUS:"West Virginia",
			stateCode:"WV",
			phoneAreaCode:"304",
			statePostCode:[{
				city:"查理斯敦(Charleston)",
				postCode:"25301~25306、25309、25311~25317、25320~25339、25350、25356~25358、25360~25362、25364、25365、25375、25387、25389、25392、25396"
			},{
				city:"亨丁顿(Huntington)",
				postCode:"25701~25729、25755、25770~25779"
			},{
				city:"摩根当(Morgantown)",
				postCode:"26501、26502、26504~26508"
			}]
		},
		{
			stateCN:"威斯康星",
			stateUS:"Wisconsin",
			stateCode:"WI",
			phoneAreaCode:"262 - 414 - 608 - 715 - 920",
			statePostCode:[{
				city:"马迪逊(Madison)",
				postCode:"53701~53708、53711、53713~53719、53725、53726、53744、53774、53777~53779、53782~53786、53788~53794"
			},{
				city:"密尔沃基(Milwaukee)",
				postCode:"53201~53228、53233~53235、53237、53259、53263、53267、53268、53270、53274、53277、53278、53280、53281、53284、53285、53288、53290、53293、53295"
			},{
				city:"拉辛(Racine)",
				postCode:"53401~53408、53490"
			}]
		},
		{
			stateCN:"怀俄明",
			stateUS:"Wyoming",
			stateCode:"WY",
			phoneAreaCode:"307",
			statePostCode:[{
				city:"斜阳(Cheyenne)",
				postCode:"82001~82003、82006~82010"
			},{
				city:"卡斯白(Casper)",
				postCode:"82601、82602、82604、82605、82609"
			},{
				city:"拉阿密(Laramie)",
				postCode:"82070~82073"
			}]
		},
		{
			stateCN:"美属萨摩亚",
			stateUS:"American Samoa",
			stateCode:"AS",
		},
		{
			stateCN:"关岛",
			stateUS:"Guam",
			stateCode:"GU",
		},
		{
			stateCN:"北马里亚纳群岛",
			stateUS:"Northern Mariana Islands",
			stateCode:"MP",
		},
		{
			stateCN:"波多黎各",
			stateUS:"Puerto Rico",
			stateCode:"PR",
		},
		{
			stateCN:"维尔京群岛",
			stateUS:"U.S. Virgin Islands",
			stateCode:"VI",
		},
		{
			stateCN:"美国本土外小岛屿",
			stateUS:"U.S. Minor Outlying Islands",
			stateCode:"UM",
		},
		{
			stateCN:"马绍尔群岛",
			stateUS:"Marshall Islands",
			stateCode:"MH",
		},
		{
			stateCN:"密克罗尼西亚联邦",
			stateUS:"Micronesia",
			stateCode:"FM",
		},
		
		{
			stateCN:"帕劳",
			stateUS:"Palau",
			stateCode:"PW",
		},
	]
}